import type Tag from '~/entities/tag'
import type TagParent from '~/entities/tagParent'
import type { Locale } from '~/types/locale'
import type TagTypeKey from '~/types/tagTypeKey'

/**
 * This is a helper to sort tag arrays based on their current translated value.
 *
 * @param typeName - If you want to use country it will work but you should rather use the countryTagCompare fn.
 * @param a - A element.
 * @param b - B element.
 * @param isParent - Here to determine the i18n prefix.
 * @returns 0 | 1 | -1.
 */
export function useSortTagsByTranslatedName() {
  const { t } = useI18n()

  return function <T extends Tag | TagParent>(
    typeName: TagTypeKey,
    a: Omit<T, 'toJSON'>,
    b: Omit<T, 'toJSON'>,
    isParent = false,
  ): number {
    const subTypeKey = isParent ? 'parentTags' : 'tags'
    return t(`${subTypeKey}.${typeName}.${a.name}`).localeCompare(
      t(`${subTypeKey}.${typeName}.${b.name}`),
    )
  }
}

/**
 * Used to sort countries while respecting important ordering
 * For example, when the current language of the app is French the first country to appear is France then Belgium then the rest aphabeticaly.
 * Check the availableImportantCountry computed in this composable for more information.
 */
export function useSortTagsByCountry() {
  const { t, locale } = useI18n()
  const availableImportantCountry = computed<string[]>(() => {
    switch (locale.value as Locale) {
      case 'fr':
        return ['france', 'belgium']
      case 'de':
        return ['germany', 'austria', 'switzerland']
      case 'en':
      default:
        return ['united-states', 'united-kingdom']
    }
  })

  return {
    sortTagsByCountry: function <T extends Tag | TagParent>(
      a: Omit<T, 'toJSON'>,
      b: Omit<T, 'toJSON'>,
    ): number {
      const subTypeKey = 'tags'

      const aScore = availableImportantCountry.value.indexOf(a.name)
      const aIsImportant = aScore !== -1
      const bScore = availableImportantCountry.value.indexOf(b.name)
      const bIsImportant = bScore !== -1
      if (aIsImportant && bIsImportant) {
        return aScore - bScore
      } else if (aIsImportant && !bIsImportant) {
        return -1
      } else if (!aIsImportant && bIsImportant) {
        return 1
      } else {
        return t(`${subTypeKey}.country.${a.name}`).localeCompare(
          t(`${subTypeKey}.country.${b.name}`),
        )
      }
    },
  } as const
}

export function useGetTranslatedTagEntityName() {
  const { t } = useI18n()

  return function (
    entity: Tag | TagParent,
    typeName: TagTypeKey | 'nationalities',
    amount = 1,
  ): string {
    const isParent = entity.className === 'TagParent'

    if (!isParent) return t(`tags.${typeName}.${entity.name}`, amount)
    else return t(`parentTags.${typeName}.${entity.name}`, amount)
  }
}
